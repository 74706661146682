class Callbacks {
  static onSuspendedCloseHandler(setFormValues, driver_status) {
    return async function onSuspendedClose() {
      setFormValues([{ field: 'status', value: driver_status }])
    }
  }

  static onSuspendedConfirmHandler(setFormState, setFormValues) {
    return async function onSuspendedConfirm(values) {
      setFormState(values)
      setFormValues([{ field: 'motive', value: _.get(values, 'motive') }])
    }
  }
}

export default Callbacks
