import React from 'react'
import { I18n } from '@front/volcanion'
import Utils from './utils'

const withContainer = Component => ({ row }) => {
  const { data } = row
  const availableEvents = _.filter(data, elem => !!elem?.key)

  const events = _.map(availableEvents, event => ({
    label: I18n.t(`event.keys.${event?.key}`),
    ...event,
    value: Utils.formatedValue(event),
    suffix: Utils.formatedSuffix(event),
  }))

  const mergedProps = {
    events
  }

  return <Component {...mergedProps} />
}

export default withContainer
