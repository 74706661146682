import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const [isDrawerOpen] = hooks.useGlobalState('isDrawerOpen')
  const version = hooks.useVersion()

  const [count] = hooks.useModelSearchCount('event', 'get', {
    initial_filter: {},
    forced_filter: { active: 1 },
    sort: ['producedAt ASC'],
    watch_type: 'count'
  })


  const mergedProps = {
    version,
    incident_count: count,
    navigateNotifications: () => navigate('/incident'),
  }
  return <Component {...mergedProps} {...props} />

}

export default withContainer
