import React, { useCallback } from 'react'
import moment from 'moment'

import { I18n } from '@front/volcanion/'
import { Grid } from '@mui/material'
import { TextField, FormDialog, DialogContent, DateTimeField } from '@front/squirtle'

const Suspension = (props) => {
  const {
    name,
    count_motive,
    onSuspendedClose,
    onSuspendedConfirm,
    initialValues,
    enable_motive
  } = props || {}
  return (
    <FormDialog
      name={name}
      maxWidth='xl'
      formProps={{
        initialValues,
        onSubmit: onSuspendedConfirm
      }}
      onCancel={onSuspendedClose}
    >
      <DialogContent>
        <Grid container spacing={4} rowSpacing={4} direction='column' alignItems={'center'}>
          <Grid item container hidden={!enable_motive} justifyContent={'center'}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                name={'motive'}
                label={I18n.t('suspend.motive', { min: count_motive, max: 250 })}
                inputProps={{ maxLength: 250 }}
                minRows={2}
                multiline
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <DateTimeField
              disablePast
              name='suspendedSince'
              dateProps={{
                label: I18n.t('date.start'),
                inputFormat: 'DD/MM/YYYY',
              }}
              timeProps={{
                label: I18n.t('hour.start'),
              }}
              required
            />
          </Grid>
          <Grid item xs>
            <DateTimeField
              disablePast
              name='suspendedUntil'
              dateProps={{
                label: I18n.t('date.end'),
                inputFormat: 'DD/MM/YYYY',
              }}
              timeProps={{
                label: I18n.t('hour.end'),
              }}
              defaultDateTime={useCallback(() => moment().set({ 'hour': 23, 'minute': 59 }), [])}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </FormDialog>
  )
}
export default React.memo(Suspension)
