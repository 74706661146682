import { I18n } from '@front/volcanion'
import { FormatUtils, TimeUtils } from '@front/volcanion/utils'

class Utils {
  static formatedNumber(event) {
    const { value, unit, format } = event || {}
    if (value === '-' || !value) return '-'
    if (unit === 'm') return FormatUtils.distance(value)
    else return !!format ? _.round(value, format) : value
  }

  static formatedDuration(event) {
    const { value } = event || {}
    if (!value) return '-'
    return TimeUtils.secondsToTime(value * 60, 'HH[h]mm')
  }

  static formatedDateTime(event) {
    const { value, format } = event || {}
    if (!value) return '-'
    return FormatUtils.formatDateBackToFront(value, format)
  }

  static formatedValue(event) {
    const { type, value } = event || {}
    switch (type) {
      case 'duration':
        return Utils.formatedDuration(event)
      case 'datetime':
        return Utils.formatedDateTime(event)
      case 'float':
      case 'number':
      case 'km':
        return Utils.formatedNumber(event)
      case 'translation':
        return I18n.t(`event.keys.${value}`)
      default:
        return value
    }
  }

  static formatedSuffix(event) {
    const { value, suffix, unit } = event || {}
    if (!value || !suffix || unit === 'm') return ''
    return suffix
  }
}

export default Utils
