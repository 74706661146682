import React from 'react'
import clsx from 'clsx'

import { Box, Typography, Toolbar, IconButton, Badge, AppBar, Grid } from '@mui/material'

import { Notifications } from '@mui/icons-material'
import Menu from './Menu/'

import './styles.scss'

const TopBar = ({ navigateNotifications, incident_count, children, version, isDrawerOpen, ...props }) =>
  <AppBar position="fixed" color="primary" className={clsx('appbar', { ['shift']: isDrawerOpen })} sx={{ zIndex: 1300 }}>
    <Toolbar className={clsx('toolbar', { ['shift']: isDrawerOpen })} >
      <Grid container item xs={12}>
        <Grid container>
          <Grid item xs={8} md={10} alignItems='center'>
            {children}
            <Box sx={{ display: 'flex', alignItems: 'center' }} />
          </Grid>
          <Grid container item justifyContent='flex-end' xs={4} md={2} alignItems='center'>
            <IconButton onClick={navigateNotifications} aria-label={`show ${incident_count} new notifications`}>
              <Badge badgeContent={incident_count} color='error' sx={{ m: 0 }}> <Box color='white'> <Notifications /> </Box> </Badge>
            </IconButton>
            <Menu {...props} />
            <Typography variant={'h4'} sx={{ ml: 1, color: 'white' }}> | </Typography>
            <Typography variant={'h5'} sx={{ ml: 1, mr: 2, color: 'white' }}> {version} </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar >

export default React.memo(TopBar)
