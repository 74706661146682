import React, { useCallback, useState } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Grid, Box, Typography, Chip, Button } from '@mui/material'
import moment from 'moment'

const EventItem = ({ value, suffix, label }) => {
  const CustomLabel = <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
    <Typography fontSize={13}>{label}</Typography>
    <Typography fontSize={13} fontWeight="bold">{value}{suffix}</Typography>
  </Box>
  return <Chip label={CustomLabel} size="small" />
}

const EventAudioAction = ({ value, expiredAt, label }) => {
  const [audio, setAudio] = useState()
  const [downloadAudio] = hooks.useVonageRecord(value)
  const onClick = useCallback(async () => downloadAudio(), [downloadAudio])
  const isExpired = moment.utc(expiredAt).isBefore(moment.utc())

  return (
    <Grid>
      {!!isExpired && <EventItem label={label} value={I18n.t('event.keys.expired')} />}
      {!audio && !isExpired && <Button
        size='small'
        color='primary'
        variant='contained'
        onClick={async () => setAudio(await onClick())}
      >
        {label}
      </Button>}
      {!!audio && !isExpired && <audio controls="controls">
        <source src={audio} type="audio/mp3" />
      </audio>}
    </Grid>
  )
}

const AssociatedInfoCell = ({ events }) => {
  return <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: .5, alignItems: 'center' }}>
    {_.map(events, event => {
      if (event.type === 'action' && event.key === 'audio')
        return <EventAudioAction {...event} />
      return <EventItem {...event} />
    })}
  </Grid>
}

export default AssociatedInfoCell
